<template>
	<main>
		<HeaderTab :title="$t('global.titre_mare_liste')"  :return_action="'navigationBack'"/>

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box" id="marelist">
							<div class="row mb-lg-5 mb-3">
								<div class="col-12 col-lg-4">
									<div class="search_mare"><b-input v-model="search" :placeholder="$t('monte.rechercher')"/><font-awesome-icon :icon="['fal', 'search']"/></div>
								</div>
								<div class="col-12 col-lg-4 text-center">
									<b-button @click="goToAddHorse" variant="primary">{{ $t('monte.add_jument') }} <font-awesome-icon :icon="['fal', 'plus']"/></b-button>
								</div>
							</div>
							<div v-if="table_busy">
								<LoadingSpinner />
							</div>
							<div v-else class="row">
								<div v-if="filtred_horses.length == 0" class="col-12 text-center">
									{{ $t('global.list_empty') }}
								</div>
								<div v-for="horse in filtred_horses" class="col-12 col-lg-4 mb-2 mb-lg-3" :key="horse.horse_id">
									<div class="mares">
										<div class="w-100" @click="goToHorse(horse.horse_id)">
											<span>
												<b>{{ horse.horse_nom }}</b>
												<i v-if="horse.pedigree.pere">{{ horse.pedigree.pere }} * {{ horse.pedigree.mere }}</i>
											</span>
										</div>
										<a href="#" @click.prevent="deleteHorse(horse)" class="icone"><font-awesome-icon :icon="['fal', 'trash-alt']"/></a>
										<a href="#" @click.prevent=""><font-awesome-icon :icon="['fal', 'angle-right']"/></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">

import Horse from '@/mixins/Horse.js'
import ActesMixin from "@/mixins/Actes.js";
import Navigation from "@/mixins/Navigation.js";
import _cloneDeep from 'lodash/cloneDeep';

export default {
	name: "HorseListe",
	mixins: [Horse, ActesMixin, Navigation],
	data () {
		return {
			items: null,
			table_busy: true ,
			search: '',
			filtred_horses: []
		}
	},
	computed: {
		hasSaillieAccess() {
			return this.$store.state.userAccess.hasSaillieAccess
		},
	},
	mounted () {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.getHorses('F').then(horse_api => {
				this.items = this.formatDataHorse(horse_api)
				this.filtred_horses = _cloneDeep(this.items)
				this.table_busy = false
			})
		},

		async deleteHorse(horse) {
			await this.hideHorseFromCA({horse_ids:horse.horse_id})
			this.init_component()
		},

		goToAddHorse() {
			this.$router.push({ name: 'horseAjout' })
		},

		goToHorse(horse_id) {
			this.$router.push({ name: 'horseFiche', params: {horse_id} })
		}
	},
	watch: {
		search(val) {
			let search_val = val.toUpperCase()
			this.filtred_horses = this.items.filter(horse => {
				return horse.horse_nom.toUpperCase().indexOf(search_val) > -1
			})
		}
	},
	components: {
		HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
	}
}
</script>
